import React from 'react'
import Navigation1 from '../Navigation/Navigation1'
import Footer from '../Footer/Footer'
import HeroTitle from '../HeroSlider/HeroTitle'

const Visa = () => {
  return (
    <>
    <Navigation1 />
    <HeroTitle title='Visa' />
    <Footer />
    </>
  )
}

export default Visa